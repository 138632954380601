<template>
<v-dialog v-model="isOpenDialog" persistent content-class="popup" style="z-index=1001;" width="460px">
  <v-card style="border-radius: 1rem;">
    <v-card-text class="border-bottom mt-5 popup popup-fileup" style="display: block;">
      <div class="popup-wrap small">
        <a class="popup-close js-close">
          <img @click.stop="closeDialog" src="@/assets/img/common/ic_close.svg" alt="">
        </a>
        <div class="popup-content">
          <div class="popup-content-form">
            <p class="popup-content-msg mt-0">{{ $t('MY_PAGE_DELIVERY_METHOD_COMFIRM_DIALOG_HEADER_TEXT') }}</p>
            <p v-if="deliveryCategoryId === '1'" class="popup-content-msg mt-1">
              <span class="text-red font-weight-bold">{{ $t('MY_PAGE_DELIVERY_METHOD_COMFIRM_DIALOG_MYSELF_LABEL') }}</span>
              {{ $t('MY_PAGE_DELIVERY_METHOD_COMFIRM_DIALOG_HEADER_2_TEXT') }}
            </p>
            <p v-else class="popup-content-msg mt-0">
              <span class="text-red font-weight-bold">{{ $t('MY_PAGE_DELIVERY_METHOD_COMFIRM_DIALOG_AGENCY_LABEL') }}</span>
              {{ $t('MY_PAGE_DELIVERY_METHOD_COMFIRM_DIALOG_HEADER_2_TEXT') }}
            </p>
            <div v-if="deliveryCategoryId === '2'" style="margin-top: 30px;" class="popup-content-table-info">
              <p class="text-red" v-for="text in errors" :key="text">{{ text }}</p>
              <table class="mt-0">
                <tbody>
                  <tr>
                    <th style="width: 100px;">
                      {{ $t('MY_PAGE_DELIVERY_METHOD_COMFIRM_DIALOG_TITLE_LABEL') }}
                    </th>
                    <td>
                      <input type="text" class="input-text" style="width: 100%;" v-model="title">
                    </td>
                  </tr>
                  <tr class="mt-2">
                    <th style="width: 100px;">
                      {{ $t('MY_PAGE_DELIVERY_METHOD_COMFIRM_DIALOG_SHIP_ADDRESS_LABEL') }}
                    </th>
                    <td>
                      <input type="text" class="input-text" style="width: 100%;" v-model="shipAddress">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <p v-if="deliveryCategoryId === '2'" class="form-privacy mt-9">
            {{$t('MY_PAGE_DELIVERY_METHOD_COMFIRM_DIALOG_AGREE_TITLE_TEXT1')}}
            <a
             class="link-text"
             @click="$routerGoNewWindow($define.PAGE_NAMES.OTHER_GUIDE_DELIVERY_PAGE_NAME, null, {lang: $i18n.locale})"
            >{{$t('MY_PAGE_DELIVERY_METHOD_COMFIRM_DIALOG_AGREE_LABEL')}}</a>
            {{$t('MY_PAGE_DELIVERY_METHOD_COMFIRM_DIALOG_AGREE_TITLE_TEXT2')}}
          </p>
          <table v-if="deliveryCategoryId === '2'" class="tbl-entry tbl-privacy mt-3">
            <tr>
              <th class="pr-3" style="width: max-content;">
                {{$t('MY_PAGE_DELIVERY_METHOD_COMFIRM_DIALOG_AGREE_LABEL') }}
                <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
              </th>
              <td class="privacy-chk">
                <label for="rule-chk">
                  <input type="checkbox" id="rule-chk" class="checkbox-input" @click="deliveryStore.ruleCheckClick" required>
                  <span class="checkbox-parts" v-html="$t('MY_PAGE_DELIVERY_METHOD_COMFIRM_DIALOG_AGREE_TEXT')"></span>
                </label>
              </td>
            </tr>
          </table>

          <div class="popup-content-form-btnWrap">
            <div class="ok-btn">
              <v-btn
               @click="deliveryStore.request()"
               color="red"
               class="white--text"
               :disabled="!ruleCheckBtnClicked"
              >{{$t("MY_PAGE_DELIVERY_METHOD_DIALOG_OK_LABEL")}}</v-btn>
            </div>
            <div class="cancle-btn mt-3">
              <v-btn @click="back()" color="#364A81" class="white--text">{{$t("MY_PAGE_DELIVERY_METHOD_DIALOG_BACK_LABEL")}}</v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
import CommonFilters from '@/common/commonFilters'
import useDelivery from '@/stores/useDelivery'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const deliveryStore = useDelivery()
    return {
      loadingStore,
      deliveryStore
    }
  },
  components : {},
  mixins     : [CommonFilters],
  props      : {
    type : {
      type : Number
    },
    isOpenDialog : {
      type    : Boolean,
      default : false
    },
    closeDialog : {
      type    : Function,
      default : Object
    },
    deliveryCategoryId : {
      type    : String,
      default : null
    }
  },
  data() {
    return {
    }
  },
  computed : {
    title : {
      get() {
        return this.deliveryStore.title
      },
      set(val) {
        this.deliveryStore.title = val
      }
    },
    shipAddress : {
      get() {
        return this.deliveryStore.shipAddress
      },
      set(val) {
        this.deliveryStore.shipAddress = val
      }
    },
    errors() {
      return this.deliveryStore.errors
    },
    ruleCheckBtnClicked() {
      return this.deliveryStore.ruleCheckBtnClicked
    }
  },
  methods : {
    back() {
      this.deliveryStore.isComfirmOpenDialog = false
      this.deliveryStore.openDeliveryMethodDialog()
    }
  }
})
</script>
<style lang="scss" scoped>
  .popup-content-form-btnWrap button {
    margin: 0 20px;
  }
  table {
    th {
      vertical-align: middle;
      width: 100px;
    }
    td {
      padding: 5px 0;
      input {
        width: 360px;
      }
    }
  }
  .ok-btn {
    text-align: center;
    button {
      border-radius: 100vh;
      width: 300px;
      height: 50px !important;
    }
  }
  .cancle-btn {
    text-align: center;
    button {
      width: 200px;
    }
  }
  em.req {
    display: inline-block;
    background-color: #E80000;
    width: 35px;
    height: 20px;
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    line-height: 19px;
  }
  em.req {
    margin-right: 5px;
    position: relative;
    top: -2px;
  }

  @media only screen and (max-width: 767px) {
    .popup-content-table-info table tbody tr {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
    }
    .popup-content-table-info table tbody tr th,
    .popup-content-table-info table tbody tr td {
      width: 100% !important;

      .input-text {
        width: 100% !important;
      }
    }
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
