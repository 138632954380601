<template>
<main id="main" class="auction">
  <div class="wrap1160">
    <div id="search">
      <div class="search_box">
        <h2>{{$t("MY_PAGE_SUCCESSFUL_BID_HISTORY_SEARCH_CONDITION_VIEW_HEADER")}}</h2>

        <div class="search-area bgLGray">
          <div class="search-area-block">
            <dl>
              <dt>{{$t("MY_PAGE_SEARCH_CONDITION_BIDDING_DATE_LABEL")}}</dt>
              <dd>
                <div class="pst-year">
                  <div>
                    <v-menu
                      ref="start_date_open"
                      v-model="start_date_open"
                      offset-y
                      :close-on-content-click="true"
                      :min-width="width_date_picker"
                    >
                    <template v-slot:activator="{ on }">
                      <input type="text" v-if="isEditStDate" :class="{ 'white-input': true, 'err': errors.start_datetime}" v-on="on" v-model="formatToStDateString" :placeholder="$t('MY_PAGE_SEARCH_CONDITION_BIDDING_TO_DATE_PLACEHOLDER')">
                      <input type="text" v-if="!isEditStDate" :class="{ 'white-input': true, 'err': errors.start_datetime}" @focus="isEditStDate = true" v-model="computedStartDateFormatted" :placeholder="$t('MY_PAGE_SEARCH_CONDITION_BIDDING_TO_DATE_PLACEHOLDER')">
                    </template>
                      <v-date-picker
                        v-model="search_condition.start_datetime"
                        scrollable
                        :day-format="day => new Date(day).getDate()"
                        @click="$refs.start_date_open.save(computedStartDateFormatted); isEditStDate = true; formatToStDateString=computedStartDateFormatted"
                        @change="$refs.start_date_open.save(computedStartDateFormatted); isEditStDate = true; formatToStDateString=computedStartDateFormatted"
                        :locale="lang"
                        :color="color_date_picker"
                      >
                      </v-date-picker>
                    </v-menu>
                  </div>
                  <span>～</span>
                  <div>
                    <v-menu
                      ref="end_date_open"
                      v-model="end_date_open"
                      :close-on-content-click="true"
                      offset-y
                      :min-width="width_date_picker"
                    >
                      <template v-slot:activator="{ on }" class="date-picker-template">
                      <input type="text" v-if="isEditEndDate" :class="{ 'white-input': true, 'err': errors.end_datetime}" v-on="on" v-model="formatToEndDateString" :placeholder="$t('MY_PAGE_SEARCH_CONDITION_BIDDING_TO_DATE_PLACEHOLDER')">
                      <input type="text" v-if="!isEditEndDate" :class="{ 'white-input': true, 'err': errors.end_datetime}" @focus="isEditEndDate = true" v-model="computedEndDateFormatted" :placeholder="$t('MY_PAGE_SEARCH_CONDITION_BIDDING_TO_DATE_PLACEHOLDER')">
                      </template>
                      <v-date-picker
                        v-model="search_condition.end_datetime"
                        scrollable
                        :day-format="day => new Date(day).getDate()"
                        @click="$refs.end_date_open.save(computedEndDateFormatted); isEditEndDate = true; formatToEndDateString=computedEndDateFormatted"
                        @change="$refs.end_date_open.save(computedEndDateFormatted); isEditEndDate = true; formatToEndDateString=computedEndDateFormatted"
                        :locale="lang"
                        :color="color_date_picker"
                      >
                      </v-date-picker>
                    </v-menu>
                  </div>
                  <span>&nbsp;</span>
                  <p v-if="errors.start_datetime && errors.end_datetime" class="err-txt">{{errors.start_datetime}}</p>
                  <p v-else-if="errors.start_datetime && !errors.end_datetime" class="err-txt">{{errors.start_datetime}}</p>
                  <p v-else-if="!errors.start_datetime && errors.end_datetime" class="err-txt">{{errors.end_datetime}}</p>
                </div>
              </dd>
            </dl>
          </div>
          <button class="btnBsc-Black btn-search" @click="search()">{{$t("MY_PAGE_SEARCH_BUTTON_LABEL")}}</button>
        </div>
      </div>

      <div class="search_result">
        <dl>
          <dt>{{$t("MY_PAGE_SEARCH_CONDITION_LABEL")}}</dt>
          <dd>
          <span v-for="(row, index) in getFiltered(filteredObject)" :key="index">{{row}}</span>
          </dd>
        </dl>
        <dl>
          <dt>{{$t("MY_PAGE_SEARCH_RESULT_LABEL")}}</dt>
          <dd>{{$t("MY_PAGE_SEARCH_RESULT_COUNT_LABEL", { count: searchResultCount})}}</dd>
        </dl>
      </div>
    </div>

    <div id="result">
      <div class="sort">
        <div class="btnSort">
          <div class="btn_sort-nmb">
            <p>{{$t("MY_PAGE_SEARCH_RESULT_DISPLAY_COUNT_LABEL")}}</p>
            <button :class="{active: isActiveClass[0]}" @click="clickItemsPerPage(itemsPerPageArr[0])">{{$t("MY_PAGE_SEARCH_RESULT_SORT_DISPLAY_COUNT_LABEL", {display_count : itemsPerPageArr[0]})}}</button>
            <button :class="{active: isActiveClass[1]}" @click="clickItemsPerPage(itemsPerPageArr[1])">{{$t("MY_PAGE_SEARCH_RESULT_SORT_DISPLAY_COUNT_LABEL", {display_count : itemsPerPageArr[1]})}}</button>
          </div>
        </div>
      </div>
      <ul>
        <div class="successful_bid_list" :class="{'under-line': item.showFlag && !item.lastItem && hideUnderLine, 'item_list': item.isSameLotIdInfo}" v-for="(item, index) in successfulBidItems" :key="index">
        <li v-if="item.showFlag">
          <div class="machine_li-img">
            <span class="machine_li-img-wrap com-item-box">
              <img class="item-box-image" v-if="item.file_path" :src="item.file_path | getFullImagePath" alt="">
              <img class="item-box-image" v-else src="@/assets/img/no_photo.jpg" alt="">
            </span>
          </div>
          <div class="machine_li-txt">
            <div v-if="!item.isSameLotIdInfo" class="mach-data">
              <p class="end-datetime">
                {{$t("MY_PAGE_ITEM_BIDDING_END_DATETIME_LABEL")}}
                {{$t("MY_PAGE_COLON_LABEL")}}
                <br class="only_sp">
                {{item.end_datetime | dateTimeFormat('YYYY/MM/DD HH:mm')}}
              </p>
            </div>
            <!-- <p class="stock-location" v-if="(!item.exhibition_member_no && item.exhibition_division === 2)">UEJ出品</p> -->
            <!-- <p class="stock-location" v-if="(item.exhibition_member_no && item.exhibition_division === 2)">UEJ出品</p> -->
            <!-- <p class="stock-location" v-if="(item.exhibition_division === 1)">会員出品</p> -->
            <p v-if="item.free_field.maker" class="price-system">{{ getMakerName(item.free_field.maker) }}</p>
            <div class="mach-data">
              <dl>
                <dt>{{$t("ITEM_ROW_BIKE_TYPE_LABEL")}}</dt>
                <dd class="line-break">{{item.free_field.bike_type}}</dd>
              </dl>
              <dl>
                <dt class="mw-5">{{$t("ITEM_ROW_CUBIC_CAPACITY")}}</dt>
                <dd>{{item.free_field.cubic_capacity_other | getCubicText($t('ITEM_FREE_FIELD_FUMEI'))}}</dd>
              </dl>
              <dl>
                <dt>{{$t("ITEM_ROW_INSPECTION_DATE")}}</dt>
                <dd>{{getInspectionTxt(item.free_field.inspec_expire, item.free_field.inspec_expire_other)}}</dd>
              </dl>
              <dl>
                <dt class="mw-5">{{$t("ITEM_ROW_MILEAGE")}}</dt>
                <dd class="line-break">{{mileageComma(item.free_field.mileage_other) || $t('ITEM_FREE_FIELD_FUMEI')}}</dd>
              </dl>
            </div>
            <div class="mach-data link_label_div" v-if="histories.filter(detail => detail.lot_no === item.lot_no && detail.order_no > 1).length > 1">
              <v-expansion-panels accordion>
                <v-expansion-panel>
                  <v-expansion-panel-header class="detail-header">{{$t("MY_PAGE_SHOW_ITEMS_LINK_LABEL")}}({{histories.filter(detail => detail.lot_no === item.lot_no && detail.order_no > 1).length}})</v-expansion-panel-header>
                  <v-expansion-panel-content class="detail-content">
                    <div
                      class="mach-data"
                      v-for="detail in histories.filter(detail => detail.lot_no === item.lot_no && detail.order_no > 1)"
                      :key="detail.lot_no"
                    >
                      <dl>
                        <dt>{{$t("MY_PAGE_ITEM_MAKER_LABEL")}}</dt>
                        <dd>{{detail.free_field.MAKER}}</dd>
                      </dl>
                      <dl>
                        <dt class="mw90">{{$t("MY_PAGE_ITEM_YEAR_LABEL")}}</dt>
                        <dd>{{detail.free_field.YYYY ? detail.free_field.YYYY + $t("MY_PAGE_YEAR_MARK") : ''}}</dd>
                      </dl>
                      <dl>
                        <dt>{{$t("MY_PAGE_ITEM_MODEL_LABEL")}}</dt>
                        <dd>{{detail.free_field.MODEL}}</dd>
                      </dl>
                      <dl>
                        <dt class="mw90">{{$t("MY_PAGE_ITEM_OPERATING_TIME_LABEL")}}</dt>
                        <dd>{{number2string(detail.free_field.OPETIM, '', 'hr')}}</dd>
                      </dl>
                      <dl>
                        <dt>{{$t("MY_PAGE_ITEM_MODEL_SERIAL_NUMBER_LABEL")}}</dt>
                        <dd>{{detail.free_field.ACTSERNO}}</dd>
                      </dl>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
          <div class="machine_li-price" style="padding: 10px 20px;">
            <div class="contractForm">
              <div class="contractPrice">
                <p v-if="!item.isSameLotIdInfo" class="conP-tit">{{$t("MY_PAGE_ITEM_CONTRACT_PRICE_LABEL")}}</p>
                <p v-if="!item.isSameLotIdInfo" class="conP-result">
                  {{$t("TOP_PAGE_PRICE_CURRENCY_MARK")}}{{number2string(item.current_price)}}
                </p>
              </div>
              <div class="contractPrice">
                <p v-if="!item.isSameLotIdInfo" class="conP-tit">{{$t("MY_PAGE_ITEM_CONTRACT_PRICE_TAX_LABEL")}}</p>
                <p v-if="!item.isSameLotIdInfo" class="bidP-result">
                  {{$t("TOP_PAGE_PRICE_CURRENCY_MARK")}}{{number2string(getPriceIncludeTax(item.current_price, item.exhibition_division))}}
                </p>
              </div>
              <div class="contractPrice">
                <p v-if="!item.isSameLotIdInfo" class="conP-tit">{{$t("MY_PAGE_ITEM_BID_PRICE_LABEL")}}</p>
                <p v-if="!item.isSameLotIdInfo" class="bidP-result">
                  {{$t("TOP_PAGE_PRICE_CURRENCY_MARK")}}{{number2string(item.bid_price)}}
                </p>
              </div>
              <!-- <div class="contractPrice">
                <p v-if="!item.isSameLotIdInfo" class="conP-tit">{{$t("MY_PAGE_ITEM_CONTRACT_BIDDER_LABEL")}}</p>
                <p v-if="!item.isSameLotIdInfo" class="bidder-result">
                  {{item.bid_user_name}}
                </p>
              </div> -->
              <div class="contractPrice">
                <p v-if="!item.isSameLotIdInfo" class="conP-tit">{{$t("MY_PAGE_ITEM_METHOD_SHIP_LABEL")}}</p>
                <p v-if="!item.isSameLotIdInfo && item.delivery_category_id === null && item.update_category_id === null" class="bidder-result">
                  {{$t("MY_PAGE_ITEM_METHOD_SHIP_UN_SELECT_LABEL")}}
                </p>
                <p v-else-if="!item.isSameLotIdInfo && item.delivery_category_id === null && item.update_category_id === '1'" class="bidder-result">
                  {{$t("MY_PAGE_ITEM_METHOD_SHIP_REQUEST_LABEL")}}
                </p>
                <p v-else-if="!item.isSameLotIdInfo && item.delivery_category_id === '2'" class="bidder-result">
                  {{$t("MY_PAGE_ITEM_METHOD_SHIP_DELIVERY_AGENCY_LABEL")}}
                </p>
                <p v-else-if="!item.isSameLotIdInfo && item.delivery_category_id === '1'" class="bidder-result">
                  {{$t("MY_PAGE_ITEM_METHOD_SHIP_DELIVERY_MYSELF_LABEL")}}
                </p>
              </div>
              <div class="bid-download">
                <div class="btnArea">
                  <a
                    v-if="!item.isSameLotIdInfo && item.delivery_category_id === null && item.update_category_id === null"
                    class="btn-img-dl"
                    @click="exhibitionItemNo = item.exhibition_item_no; deliveryStore.itemFreeField = item.free_field; deliveryStore.openDeliveryMethodDialog()"
                  >
                    {{$t("MY_PAGE_ITEM_SELECT_METHOD_SHIP_BUTTON_LABEL")}}
                  </a>
                  <v-badge
                    v-if="!item.isSameLotIdInfo && item.update_category_id !== null"
                    bordered
                    color="error"
                    :content="item.item_chat_un_read_count"
                    :value="item.item_chat_un_read_count > 0 ? true : false"
                    overlap
                    class="mr-2 ml-2"
                  >
                    <a
                      class="btn-img-dl pr-2 pl-2"
                      @click="$routerGo($define.PAGE_NAMES.CHAT_REQUEST_PAGE_NAME, item.item_request_no)"
                    >
                      {{$t("MY_PAGE_ITEM_REQUEST_CHAT_BUTTON_LABEL")}}
                    </a>
                  </v-badge>
                  <v-badge
                    v-if="!item.isSameLotIdInfo && item.delivery_category_id !== null"
                    bordered
                    color="error"
                    :content="item.bid_chat_un_read_count"
                    :value="item.bid_chat_un_read_count > 0 ? true : false"
                    overlap
                    class="mr-2 ml-2"
                  >
                    <a
                      class="btn-img-dl pr-2 pl-2"
                      @click="$routerGo($define.PAGE_NAMES.CHAT_BID_PAGE_NAME, item.exhibition_item_no)"
                    >
                      {{$t("MY_PAGE_ITEM_BID_CHAT_BUTTON_LABEL")}}
                    </a>
                  </v-badge>
                </div>
              </div>
              <!-- <div class="bid-download">
                <div class="btnArea">
                  <a v-if="!item.isSameLotIdInfo" class="btn-img-dl" @click="downloadFile(item.exhibition_item_no)">{{$t("MY_PAGE_ITEM_PRODUCT_IMAGE_DOWNLOAD_BUTTON_LABEL")}}<img src="@/assets/img/common/ic_dl_bgreen.svg" alt=""></a>
                </div>
              </div> -->
            </div>
          </div>
        </li>
        </div>
      </ul>
      <a v-if="hasMore" @click="isMore" class="btnBsc-Black btn-more">{{$t("MY_PAGE_SHOW_MORE_BUTTON_LABEL")}}</a>
    </div>
  </div>

  <!-- 配送方法選択モーダル start -->
  <DeliveryMethodDialog
    :isOpenDialog="isFormOpenDialog"
    :exhibitionItemNo="exhibitionItemNo"
    :closeDialog="closeDeliveryMethodDialog"/>
  <!-- 配送方法選択モーダル end -->

  <!-- 配送方法確認モーダル start -->
  <DeliveryMethodComfirmDialog
    :isOpenDialog="isComfirmOpenDialog"
    :deliveryCategoryId="deliveryCategoryId"
    :closeDialog="closeDeliveryMethodComfirmDialog"/>
  <!-- 配送方法確認モーダル end -->

  <!-- 配送方法完了モーダル start -->
  <DeliveryMethodCompletionDialog
    :isOpenDialog="isCompletionOpenDialog"
    :closeDialog="closeDeliveryMethodCompletionDialog"/>
  <!-- 配送方法完了モーダル end -->
</main>
</template>

<script>
import Methods from '@/api/methods'
import CommonFilters from '@/common/commonFilters'
import useDelivery from '@/stores/useDelivery'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'
import moment from 'moment'
import DeliveryMethodComfirmDialog from '../../components/MyPage/parts/DeliveryMethodComfirmDialog'
import DeliveryMethodCompletionDialog from '../../components/MyPage/parts/DeliveryMethodCompletionDialog'
import DeliveryMethodDialog from '../../components/MyPage/parts/DeliveryMethodDialog'

export default defineComponent({
  mixins : [CommonFilters],
  setup() {
    const loadingStore = useLoading()
    const deliveryStore = useDelivery()
    return {
      loadingStore,
      deliveryStore
    }
  },
  components : {
    DeliveryMethodDialog,
    DeliveryMethodComfirmDialog,
    DeliveryMethodCompletionDialog
  },
  data() {
    return {
      itemsPerPageArr         : [50, 100],
      isActiveClass           : [true, false],
      width_date_picker       : '247px',
      color_date_picker       : '#364A81',
      lang                    : '',
      hideUnderLine           : false,
      start_date_open         : false,
      end_date_open           : false,
      isEditStDate            : false,
      isEditEndDate           : false,
      input_start_datetime    : '',
      input_end_datetime      : '',
      search_condition        : {
        start_datetime         : '',
        end_datetime           : ''
      },
      filteredObject          : {},
      mypageConstants         : [],
      histories               : [],
      successfulBidHistories  : [],
      searchResultCount       : 0,
      hasMore                 : false,
      showFlag                : false,
      activePage              : 1,
      pages                   : 1,
      itemsPerPage            : 0,
      itemCount               : 0,
      errors                  : '',
      requestUnCheckedCntData : [],
      bidUnCheckedCntData     : [],
    }
  },
  mounted() {
    this.initScreen()
    this.getMypageConstants()
  },
  methods : {
    initScreen() {
      this.input_start_datetime = moment().startOf('month')
        .format('YYYY-MM-DD')
      this.input_end_datetime = moment().format('YYYY-MM-DD')
      this.search_condition.start_datetime = moment().startOf('month')
        .format('YYYY-MM-DD')
      this.search_condition.end_datetime = moment().format('YYYY-MM-DD')
      this.itemCount = this.itemsPerPageArr[0]
      this.itemsPerPage = this.itemsPerPageArr[0]
      this.lang = this.$i18n.locale

      this.search()
    },
    formatDate(date) {
      if (!date) {
        return null
      }
      if (!date.match(/^\d{4}\-\d{2}\-\d{2}$/)) {
        return date
      }
      const [year, month, day] = date.split('-')
      return `${year}/${month}/${day}`
    },
    getMypageConstants() {
      this.loadingStore.setLoading(true)
      Methods.apiExcute('private/get-mypage-constants', this.$i18n.locale)
        .then(data => {
          this.loadingStore.setLoading(false)
          this.mypageConstants = data
        })
        .catch(error => {
          console.log(error)
          this.loadingStore.setLoading(false)
          Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    clickItemsPerPage(value) {
      if (value === this.itemsPerPageArr[0]) {
        this.isActiveClass = [true, false]
      } else if (value === this.itemsPerPageArr[1]) {
        this.isActiveClass = [false, true]
      }
      this.itemCount = value
      this.itemsPerPage = value
    },
    isMore() {
      this.itemCount += this.itemsPerPage
    },
    showDetails(lotId) {
      this.showFlag = !this.showFlag

      for (let i = 0;i < this.successfulBidHistories.length;i++) {
        if (lotId === this.successfulBidHistories[i].lot_id) {
          this.successfulBidHistories[i].showFlag = true
        }
      }
      this.hideUnderLine = true
    },
    hideDetails(lotId) {
      this.showFlag = !this.showFlag
      for (let i = 0;i < this.successfulBidHistories.length;i++) {
        const item = this.successfulBidHistories[i]
        if (lotId === item.lot_id) {
          if (item.isSameLotIdInfo) {
            item.showFlag = false
          }
        }
      }
      this.hideUnderLine = false
    },
    getScreenCondition() {
      const search_condition = {
        start_datetime         : this.formatDate(this.input_start_datetime),
        end_datetime           : this.formatDate(this.input_end_datetime)
      }
      return search_condition
    },
    search() {
      this.hideUnderLine = false
      this.showFlag = false
      this.errors = ''
      this.searchResultCount = 0
      for (let i = 0;i < this.isActiveClass.length;i++) {
        if (this.isActiveClass[i]) {
          this.itemCount = this.itemsPerPageArr[i]
        }
      }
      this.successfulBidHistories = []
      const search_condition = this.getScreenCondition()
      this.filteredObject = JSON.parse(JSON.stringify(search_condition))
      this.getSuccessfulBidHistory(search_condition, histories => {
        this.successfulBidHistories = histories
      })
    },
    getSuccessfulBidHistory(search_condition, callback) {
      this.loadingStore.setLoading(true)
      console.log(`search_condition = ${JSON.stringify(search_condition)}`)
      Methods.apiExcute('private/get-successful-bid-history', this.$i18n.locale, search_condition)
        .then(histories => {
          this.histories = histories
          this.requestUnCheckedCntData = histories.request_count
          this.bidUnCheckedCntData = histories.bid_count
          this.loadingStore.setLoading(false)
          if (typeof histories === 'undefined' || histories === null) {
            callback(null)
          } else {
            const lotIdList = histories.filter((value, index, item) => {
              return item.findIndex(v => value.lot_id === v.lot_id) === index
            })

            const tmpMap = new Map()
            for (let i = 0;i < lotIdList.length;i++) {
              const items = histories.filter(x => x.lot_id === lotIdList[i].lot_id)
              tmpMap.set(String(lotIdList[i].lot_id), items)
            }

            const displayList = []
            let lotId = ''
            let item = null
            lotIdList.forEach((lot, i) => {
              lotId = lot.lot_id
              const items = tmpMap.get(String(lotId))
              if (items.length > 1) {
                for (let j = 0;j < items.length;j++) {
                  item = items[j]

                  if (j === 0) {
                    item.showFlag = true
                    item.firstItem = true
                    item.lastItem = false
                  } else {
                    item.isSameLotIdInfo = true
                    item.showFlag = false
                    item.firstItem = false
                    item.lastItem = false
                    if (j === (items.length - 1)) {
                      item.lastItem = true
                    }
                  }
                  displayList.push(item)
                }
              } else {
                item = items[0]
                item.showFlag = true
                item.firstItem = true
                item.lastItem = true
                displayList.push(item)
              }
            })
            callback(displayList)
          }
        })
        .catch(error => {
          console.log(error)
          this.loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    setComputedStartDate() {
      this.input_start_datetime = this.search_condition.start_datetime
    },
    setComputedEndDate() {
      this.input_end_datetime = this.search_condition.end_datetime
    },
    getFiltered(filter) {
      const filtered = []

      if (filter.start_datetime || filter.end_datetime) {
        filtered.push(`${filter.start_datetime || ''}～${filter.end_datetime || ''}`)
      }

      return filtered
    },
    downloadFile(exhibitionItemNo) {
      this.loadingStore.setLoading(true)
      const params = {
        exhibitionItemNo
      }
      return Methods.apiExcute('public/download-item-images', this.$i18n.locale, params)
        .then(data => {
          this.loadingStore.setLoading(false)
          window.location.href = data.url
        })
        .catch(error => {
          console.log(error)
          this.loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    // 値が「-」(数値以外)や空文字の場合は単位を表示しないようにする
    number2string(val, nullText = '', unit = '') {
      if (typeof val === 'undefined' || val === null || String(val).length === 0) {
        return nullText
      }
      return isNaN(val) ? String(val) : (val.numberString() + unit)
    },
    getMakerName(mkId) {
      if (this.mypageConstants) {
        const maker = this.mypageConstants.find(x => x.key_string === 'SELECT_MAKER' && String(x.value1) === String(mkId)) || {}
        return maker.value2 || ''
      }
      return ''
    },
    mileageComma(val) {
      if (val) return this.$options.filters.number2string(val, null, 'km')
      else return null
    },
    closeDeliveryMethodDialog() {
      this.deliveryStore.closeDeliveryMethodDialog()
    },
    closeDeliveryMethodComfirmDialog() {
      this.deliveryStore.closeDeliveryMethodComfirmDialog()
    },
    closeDeliveryMethodCompletionDialog() {
      this.deliveryStore.closeDeliveryMethodCompletionDialog()
      this.search()
    },
    getPriceIncludeTax(price, division) {
      if (division === 1) return price
      const taxConstants = this.mypageConstants.filter(x => x.key_string === 'TAX_RATE')
      const tax = taxConstants && taxConstants.length > 0 ? Number(taxConstants[0].value1) : 0
      const taxed = price + (price * tax / 100)
      return taxed.numberString()
    },
    getInspectionTxt(optionId, inputTxt) {
      if (this.mypageConstants) {
        const fumeiNasi = this.mypageConstants.find(x => x.key_string === 'SELECT_FUMEI_NASI' && x.value1 === String(optionId)) || {}
        return fumeiNasi.value3 === '1' ? inputTxt : fumeiNasi.value2 || ''
      }
      return ''
    }
  },
  computed : {
    formatToStDateString : {
      get() {
        return this.formatDate(this.input_start_datetime)
      },
      set(value) {
        this.input_start_datetime = value
      }
    },
    formatToEndDateString : {
      get() {
        return this.formatDate(this.input_end_datetime)
      },
      set(value) {
        this.input_end_datetime = value
      }
    },
    computedStartDateFormatted : {
      get() {
        this.setComputedStartDate()
        return this.formatDate(this.search_condition.start_datetime)
      },
      set(value) {
        this.search_condition.start_datetime = value
      }
    },
    computedEndDateFormatted : {
      get() {
        this.setComputedEndDate()
        return this.formatDate(this.search_condition.end_datetime)
      },
      set(value) {
        this.search_condition.end_datetime = value
      }
    },
    successfulBidItems() {
      const histories = this.successfulBidHistories

      const lotIdList = histories.filter((value, index, item) => {
        return item.findIndex(v => value.lot_id === v.lot_id) === index
      })

      const slicedList = lotIdList.slice(0, this.itemCount)

      let sameLotIdCount = 0
      for (let i = 0;i < slicedList.length;i++) {
        const items = histories.filter(x => x.lot_id === slicedList[i].lot_id)
        if (items.length > 1) {
          sameLotIdCount += items.slice(1).length
        }
      }
      this.searchResultCount = slicedList.length.toLocaleString()
      this.hasMore = (lotIdList.length - this.itemCount) > 0
      return histories.slice(0, (this.itemCount + sameLotIdCount))
    },
    exhibitionItemNo : {
      get() {
        return this.deliveryStore.exhibitionItemNo
      },
      set(value) {
        this.deliveryStore.exhibitionItemNo = value
      }
    },
    deliveryCategoryId() {
      return this.deliveryStore.deliveryCategoryId
    },
    isFormOpenDialog() {
      return this.deliveryStore.isFormOpenDialog
    },
    isComfirmOpenDialog() {
      return this.deliveryStore.isComfirmOpenDialog
    },
    isCompletionOpenDialog() {
      return this.deliveryStore.isCompletionOpenDialog
    },
  },
  watch : {
    '$i18n.locale'() {
      this.lang = this.$i18n.locale
      this.getMypageConstants()
      this.search()
    },
    errors(value) {
      const keys = Object.keys(value)
      if (keys.length > 0 && this.$refs[keys[0]]) {
        this.$refs[keys[0]].focus()
      }
    }
  }
})
</script>
<style lang="scss">
  .v-expansion-panel::before {
    -webkit-box-shadow: unset;
    box-shadow: unset;
  }
  .detail-content {
    .v-expansion-panel-content__wrap {
      padding: 0;
    }
    .mach-data {
      margin-bottom: 20px;
    }
  }
  .detail-header {
    text-align: center !important;
    color: #364A81 !important;
    display: unset;
    font-size: 16px;
    font-weight: 700;
    .v-expansion-panel-header__icon {
     margin-left: unset;
    }
  }
</style>
<style scoped lang="scss">
  .bid-download {
    height: 45px;
    .btn-img-dl {
      height: 40px !important;
    }
  }
  @media only screen and (min-width: 768px) {
    .machine_li-price {
      // height:214px;
    }
  }
  .btnArea {
    justify-content: center !important;
    width: 100% !important;
    .btn-img-dl {
      width: 100% !important;
    }
  }
  .item-box-image {
    min-height: 214px !important;
  }
  .mw90 {
    min-width: 90px !important;
  }
</style>
<style type="scss">
  @media only screen and (max-width: 767px) {
    #main #result .successful_bid_list > li .machine_li-img {
      width: 100% !important;
    }
  }
</style>
<style scoped lang="css" src="@/assets/css/mypage.css"></style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
