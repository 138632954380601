<template>
<v-dialog v-model="isOpenDialog" persistent content-class="popup" style="z-index=1001;" width="460px">
  <v-card style="border-radius: 1rem;">
    <v-card-text class="border-bottom mt-5 popup popup-fileup" style="display: block;">
      <div class="popup-wrap middle">
        <a class="popup-close js-close">
          <img @click.stop="closeDialog" src="@/assets/img/common/ic_close.svg" alt="">
        </a>
        <div class="popup-content">
          <div class="popup-content-form">
            <p class="popup-content-msg mt-0">{{$t("MY_PAGE_DELIVERY_METHOD_DIALOG_HEADER_TEXT")}}</p>
            <p class="popup-content-msg mt-1 text-red">{{$t("MY_PAGE_DELIVERY_METHOD_DIALOG_HEADER_2_TEXT")}}</p>
            <p class="text-red ml-16" v-for="text in errors" :key="text">{{ text }}</p>
            <div class="form-inline text-center mt-5">
              <a
                v-bind:class="{contentBlue : selectedAgencyFlg === true, contentGray : selectedAgencyFlg === false}"
                @click="selectedAgencyFlg = true; selectedMyselfFlg = false; deliveryStore.deliveryCategoryId = '2'"
              >
                <p class="btnBsc">{{$t("MY_PAGE_DELIVERY_METHOD_DIALOG_DELIVERY_AGENCY_BUTTON_LABEL")}}</p>
                <div class="delivery-img"><img src="@/assets/img/common/popup-agency-img01.png" alt=""></div>
              </a>
              <a
                v-bind:class="{contentBlue : selectedMyselfFlg === true, contentGray : selectedMyselfFlg === false}"
                @click="selectedAgencyFlg = false; selectedMyselfFlg = true; deliveryStore.deliveryCategoryId = '1'"
              >
                <p>{{$t("MY_PAGE_DELIVERY_METHOD_DIALOG_DELIVERY_MYSELF_BUTTON_LABEL")}}</p>
                <div class="delivery-img"><img src="@/assets/img/common/popup-agency-img02.png" alt=""></div>
              </a>
            </div>
          </div>
          <div class="popup-content-form-btnWrap">
            <div class="ok-btn">
              <v-btn @click="deliveryStore.selected()" color="red" class="white--text">{{$t("MY_PAGE_DELIVERY_METHOD_DIALOG_OK_LABEL")}}</v-btn>
            </div>
            <div class="cancle-btn mt-3">
              <v-btn @click.stop="closeDialog" color="#364A81" class="white--text">{{$t("MY_PAGE_DELIVERY_METHOD_DIALOG_CANCLE_LABEL")}}</v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
import CommonFilters from '@/common/commonFilters'
import useDelivery from '@/stores/useDelivery'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const deliveryStore = useDelivery()
    return {
      loadingStore,
      deliveryStore
    }
  },
  components : {},
  mixins     : [CommonFilters],
  props      : {
    type : {
      type : Number
    },
    isOpenDialog : {
      type    : Boolean,
      default : false
    },
    closeDialog : {
      type    : Function,
      default : Object
    },
    exhibitionItemNo : {
      type    : Number,
      default : 0
    }
  },
  data() {
    return {
    }
  },
  computed : {
    selectedAgencyFlg : {
      get() {
        return this.deliveryStore.selectedAgencyFlg
      },
      set(val) {
        this.deliveryStore.selectedAgencyFlg = val
      }
    },
    selectedMyselfFlg : {
      get() {
        return this.deliveryStore.selectedMyselfFlg
      },
      set(val) {
        this.deliveryStore.selectedMyselfFlg = val
      }
    },
    errors() {
      return this.deliveryStore.errors
    }
  }
})
</script>
<style lang="scss" scoped>
  .popup-content-form-btnWrap button {
    margin: 0 20px;
  }
  .form-inline > a {
    display: inline-block !important;
    margin: 10px 40px;
    box-shadow: 0 0px 10px 0 rgba(0,0,0,0.5);
  }
  .form-inline > a > p {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    height: 40px;
    padding: 5px;
  }
  .delivery-img {
    padding: 20px 50px;
    width: 400px;
    height: 300px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .contentGray {
    text-align: center;
    border:5px solid #dcdcdb;
    border-radius: 10px;
    p {
      color: #364A81;
      background-color: #dcdcdb;
    }
  }
  .contentBlue {
    text-align: center;
    border:5px solid #364A81;
    border-radius: 10px;
    p {
      color: #fff;
      background-color: #364A81;
    }
  }
  .ok-btn {
    text-align: center;
    button {
      border-radius: 100vh;
      width: 300px;
      height: 50px !important;
    }
  }
  .cancle-btn {
    text-align: center;
    button {
      width: 200px;
    }
  }

  @media only screen and (max-width: 767px) {
    .delivery-img {
      width: auto;
      height: auto;
    }
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
