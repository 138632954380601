import i18n from '@/language/i18n'
import router from '@/router'
import {defineStore} from 'pinia'
import Methods from '../api/methods'
import useLoading from './useLoading'
const loadingStore = useLoading()

const useDelivery = defineStore('useDelivery', {
  state : () => {
    return {
      locale                 : 'ja',
      exhibitionItemNo       : 0,
      deliveryCategoryId     : null,
      title                  : null,
      itemFreeField          : {},
      shipAddress            : null,
      selectedAgencyFlg      : false,
      selectedMyselfFlg      : false,
      isFormOpenDialog       : false,
      isComfirmOpenDialog    : false,
      isCompletionOpenDialog : false,
      errors                 : [],
      ruleCheckBtnClicked    : false
    }
  },
  getters : {
  },
  actions : {
    selected() {
      this.errors = []
      if (this.deliveryCategoryId === '1') {
        this.isFormOpenDialog = false
        this.ruleCheckBtnClicked = true
        this.openDeliveryMethodComfirmDialog()
      } else if (this.deliveryCategoryId === '2') {
        loadingStore.setLoading(true)
        Methods.apiExcute('private/get-member-info', this.locale)
          .then(data => {
            loadingStore.setLoading(false)
            if (data) {
              this.shipAddress = data.free_field.shipAddress
            }
            this.isFormOpenDialog = false
            this.openDeliveryMethodComfirmDialog()
            process.nextTick(() => {
              this.resetRuleCheck()
            })
          })
          .catch(error => {
            loadingStore.setLoading(false)
            this.errors = Methods.parseHtmlResponseError(router, error)
          })
      } else {
        this.errors.push(i18n.t('MY_PAGE_DELIVERY_METHOD_REQUIRED_ERROR_MESSAGE'))
      }
    },
    request() {
      console.log('deliveryRequest')
      // Check agree button in case of 配送代行 only
      if (this.deliveryCategoryId === '2' && this.ruleCheckBtnClicked === false) {
        return
      }
      this.errors = []
      if (this.deliveryCategoryId === '2' && (this.title === null || this.title.trim() === '')) {
        this.errors.push(i18n.t('MY_PAGE_DELIVERY_METHOD_TITLE_REQUIRED_ERROR_MESSAGE'))
      }
      if (this.deliveryCategoryId === '2' && (this.shipAddress === null || this.shipAddress.trim() === '')) {
        this.errors.push(i18n.t('MY_PAGE_DELIVERY_METHOD_SHIP_ADDRESS_REQUIRED_ERROR_MESSAGE'))
      }
      if (this.errors.length === 0) {
        loadingStore.setLoading(true)
        const params = {
          exhibitionItemNo   : this.exhibitionItemNo,
          deliveryCategoryId : this.deliveryCategoryId,
          shipAddress        : this.shipAddress,
          title              : this.title,
          maker              : this.itemFreeField.maker,
          bikeType           : this.itemFreeField.bike_type,
          bodyTypes          : this.itemFreeField.body_types
        }
        Methods.apiExcute('private/request-delivery-method', this.locale, params)
          .then(data => {
            this.closeDeliveryMethodComfirmDialog()
            this.openDeliveryMethodCompletionDialog()
            this.exhibitionItemNo = 0
            this.resetDeliverySelect()
          })
          .catch(error => {
            loadingStore.setLoading(false)
            this.errors = Methods.parseHtmlResponseError(router, error)
          })
      }
      loadingStore.setLoading(false)
    },
    openDeliveryMethodDialog() {
      this.errors = []
      this.isFormOpenDialog = true
    },
    closeDeliveryMethodDialog() {
      this.isFormOpenDialog = false
      this.resetDeliverySelect()
    },
    openDeliveryMethodComfirmDialog() {
      this.errors = []
      this.isComfirmOpenDialog = true
    },
    closeDeliveryMethodComfirmDialog() {
      this.isComfirmOpenDialog = false
      this.resetDeliverySelect()
    },
    openDeliveryMethodCompletionDialog() {
      this.errors = []
      this.isCompletionOpenDialog = true
    },
    closeDeliveryMethodCompletionDialog() {
      this.isCompletionOpenDialog = false
      this.resetDeliverySelect()
    },
    resetDeliverySelect() {
      this.deliveryCategoryId = null
      this.selectedAgencyFlg = false
      this.selectedMyselfFlg = false
      this.shipAddress = null
      this.title = null
    },
    resetRuleCheck() {
      // 更新ボタンの活性・非活性
      const rule = document.getElementById('rule-chk')
      if (rule) {
        rule.checked = false
        this.ruleCheckBtnClicked = false
      }
    },
    ruleCheckClick() {
      // 更新ボタンの活性・非活性
      if (document.getElementById('rule-chk').checked === false) {
        console.log('uncheck')
        this.ruleCheckBtnClicked = false
      } else {
        console.log('checked')
        this.ruleCheckBtnClicked = true
      }
    },
  }
})

export default useDelivery
