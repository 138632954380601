<template>
<v-dialog v-model="isOpenDialog" persistent content-class="popup" style="z-index=1001;" width="460px">
  <v-card style="border-radius: 1rem;">
    <v-card-text class="border-bottom mt-5 popup popup-fileup" style="display: block;">
      <div class="popup-wrap small">
        <a class="popup-close js-close">
          <img @click.stop="closeDialog" src="@/assets/img/common/ic_close.svg" alt="">
        </a>
        <div class="popup-content">
          <div class="popup-content-form">
            <p class="popup-content-msg mt-0">{{$t("MY_PAGE_DELIVERY_METHOD_COMPLETION_DIALOG_HEADER_TEXT")}}</p>
          </div>
          <div class="popup-content-form-btnWrap">
            <v-btn @click.stop="closeDialog" color="#364A81" class="white--text">{{$t("MY_PAGE_DELIVERY_METHOD_DIALOG_CANCLE_LABEL")}}</v-btn>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
import CommonFilters from '@/common/commonFilters'
import useDelivery from '@/stores/useDelivery'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const deliveryStore = useDelivery()
    return {
      loadingStore,
      deliveryStore
    }
  },
  components : {},
  mixins     : [CommonFilters],
  props      : {
    isOpenDialog : {
      type    : Boolean,
      default : false
    },
    closeDialog : {
      type    : Function,
      default : Object
    }
  },
  data() {
    return {
      errors    : {}
    }
  },
  methods : {
  }
})
</script>
<style lang="scss" scoped>
  .popup-content-form-btnWrap button {
    margin: 0 20px;
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
